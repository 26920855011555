<script>
  import { createNamespacedHelpers } from 'vuex'
  import ProductCategoryHeader from '@/components/products/ProductCategoryHeader'
  import ProductFilter from '@/components/products/ProductFilter'
  import ProductTileGrid from '@/components/products/ProductTileGrid'
  import IconClose from '@/images/icons/clear-filters.svg'
  import FilterIcon from '@/images/icons/filter-icon.svg'

  const { mapActions, mapState, mapGetters } = createNamespacedHelpers('products')

  /** The PLP main app component. Uses Vuex action to load the products on page load based on the Craft category
   * slug. If no slug is present or the slug is invalid it will get all products.
   * See (Craft module controller): /modules/hauteliving/controllers/SiteController.php actionGetProducts()
   * See (Craft module service): /modules/hauteliving/services/SiteService.php getProductList() */

  export default {
    name: 'ProductListingPage',

    components: {
      ProductCategoryHeader,
      ProductFilter,
      ProductTileGrid,
      IconClose,
      FilterIcon,
    },

    props: {
      dimensionFilters: {
        type: Array,
        default: () => [],
      },
      featuredCombined: {
        type: Array,
        default: () => [],
      },
      /** The title to display above the listing */
      segmentCount: {
        type: Number,
        default: 0,
      },
      prodLength: {
        type: Number,
        default: 0,
      },
      title: {
        type: String,
        default: '',
        required: false,
      },
      priceFilters: {
        type: Array,
        default: () => [],
      },
      brandedTitle: {
        type: Object,
        default: () => ({}),
      },
      brandId: {
        type: Number,
        default: null,
      },
      brandedSlug: {
        type: String,
        default: '',
        required: false,
      },
      /** The breadcrumb of product category */
      breadCrumb: {
        type: Array,
        default: () => [],
        required: false,
      },
      /** The parent title (if a child category) */
      parentTitle: {
        type: String,
        default: null,
      },
      showFilterBlock: {
        type: Boolean,
        default: false,
      },
      /** An optional summary to display above the listing */
      summary: {
        type: String,
        default: null,
      },
      /** A Craft product category slug */
      category: {
        type: String,
        default: null,
      },
      categoryId: {
        type: [String, Number],
        default: null,
      },
      /** A Craft product category name */
      categoryName: {
        type: String,
        default: null,
      },
      /** An array of curated product IDs */
      curatedProducts: {
        type: Array,
        default: () => [],
      },
      /** Whether to only show "buy now" products */
      buyNow: {
        type: Boolean,
        default: false,
      },
      /** The options for the filters */
      filterOptions: {
        type: Object,
        default: null,
      },
      /** The handles of filters to hide */
      hiddenFilters: {
        type: Array,
        default: null,
      },
      /** Whether or not to hide the price line all together. **/
      hidePrice: {
        type: Boolean,
        required: false,
        default: false,
      },
      /** Whether or not to hide the label "Price on Request" **/
      hidePriceOnRequestLabel: {
        type: Boolean,
        required: false,
        default: false,
      },
      /** An initial status filter to set on page load */
      initialFilter: {
        type: String,
        default: null,
        validator: (propValue) => {
          const validTypes = ['quickShip', 'inShowroom', '']
          return validTypes.indexOf(propValue) > -1
        },
      },
      /** Is the user logged in as a trade account user */
      isTradeAccount: {
        type: Boolean,
        required: false,
      },
    },

    data() {
      return {
        clicked: false,
        filterBarOpen: false,
        moreWidth: true,
        isVisible: false,
        giveOpacity: false,
        emptyFilters: false,
        filtersCount: 0,
        firstSelected: {},
        pcon: false,
        clickedpc:false,
      }
    },

    watch: {
      selectedFilters(item) {
        if (item.length === 0) {
          this.isVisible = false
        } else {
          this.isVisible = true
        }
      },
      filterValues() {
        this.filterValues.quickShip ? (this.clicked = true) : (this.clicked = false)
        this.filterValues.pcon ? (this.clickedpc = true) : (this.clickedpc = false)
      },
      firstSelectedBrand(item) {
        Object.entries(item).length !== 0 ? (this.firstSelected = item) : (this.firstSelected = {})
      },
      appendFilters(item) {
        if (item.length === 0) {
          this.emptyFilters = true
          this.filtersCount = 0
        } else {
          if (item[0].type === 'Brand') {
            this.filtersCount = item.length
          }
        }
      },
    },

    computed: {
      ...mapState(['filterValues', 'sortOptions', 'sort', 'appendFilters', 'firstSelectedBrand']),
      /** Vuex getter methods */
      ...mapGetters([
        'getPaginationData',
        'getIsLoading',
        'getSelectedOption',
        'getFilters',
        'getPaginatedFeaturedProducts',
      ]),
      shownQuantity: function () {
        return (
          '<span data-cy="currentNumberOfProducts">' + this.getPaginationData.current + '</span>'
        )
      },
      getLowHighPriceOptions() {},

      totalQuantity: function () {
        return '<span data-cy="totalNumberOfProducts">' + this.getPaginationData.total + '</span>'
      },
      categoryString() {
        let catString = this.title
        if (this.parentTitle) {
          catString = this.parentTitle + '/' + this.title
        }
        return catString
      },
      // Retrieves product status from URL query param
      status: {
        get() {
          const uri = window.location.search.substring(1)
          const params = new URLSearchParams(uri)

          return params.get('s')
        },
      },
    },
    async created() {
      await this.$store.dispatch('products/setSegmentCount', this.segmentCount)
      await this.$store.dispatch('products/setProductsLength', this.prodLength)
      this.$store.dispatch('products/setFeaturedCombined', this.featuredCombined)
      let isLighning = false
      if (this.categoryName === 'Modern Lighting' || this.parentTitle === 'Modern Lighting') {
        isLighning = true
      }
      await this.$store.dispatch('products/getCurrentCategory', isLighning)
      /** Initialize the filters */
      await this.$store.dispatch('products/initFilters', {
        filterOptions: this.filterOptions,
        hiddenFilters: this.hiddenFilters,
      })

      await this.$store.dispatch('products/initPriceFilters', this.priceFilters)
      await this.$store.dispatch('products/initDimensionFilters', this.dimensionFilters)

      /** If an initial filter is defined (either quickShip or inShowroom) set it */
      if (this.initialFilter) {
        await this.$store.dispatch('products/setFilterValue', {
          filter: this.initialFilter,
          value: true,
        })
      }
      /** Dispatch actions in the Vuex store to get the products by category */

      await this.$store.dispatch('products/initProducts', {
        categoryId: this.categoryId,
        buyNow: this.buyNow ? 'true' : null,
        curated: this.curatedProducts,
        isTradeAccount: this.isTradeAccount,
      })

      if (this.status) {
        await this.setFilterValue({ filter: this.getProductStatusHandle(this.status), value: true })
      }
    },

    mounted() {
      this.applyBrowsingSnapshot()
      // Send the appropriate GTM event
      if (this.$gtm.enabled()) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'View_Category',
          email: this.$store.getters['global/currentUser']
            ? this.$store.getters['global/currentUser'].email
            : '',
          product_category: this.categoryString,
        })
      }
    },

    methods: {
      ...mapActions([
        'setFilterValue',
        'applyBrowsingSnapshot',
        'setSort',
        'clearFilters',
        'clearAllFilters',
        'remoteSelectedFilter',
      ]),
      ...mapGetters(['getUsedFilters']),
      showSidebarFilters() {
        this.moreWidth = !this.moreWidth
        this.giveOpacity = true
      },
      newUpdateFilters(data) {
        this.selectedFilters = data
      },
      quickShipChecked() {
        this.clicked = !this.clicked
        const filter = this.getFilters.filter((item) => item.handle === 'quickShip')[0]

        if (this.clicked) {
          const url = new URL(window.location)
          url.searchParams.set('s', filter.handle.toLowerCase())
          window.history.pushState({}, '', url)
        } else {
          const url = new URL(window.location)
          url.searchParams.delete('s')
          window.history.pushState({}, '', url)
        }

        this.setFilterValue({ filter: 'quickShip', value: this.clicked })
      },
      pconChecked() {
        this.clickedpc = !this.clickedpc
       // const filter = this.getFilters.filter((item) => item.handle === 'pcon')[0]
       this.setFilterValue({
          filter: 'pcon',
          value: false,
        })
        if (this.clickedpc) {
          const url = new URL(window.location)
          url.searchParams.set('filter', 'pcon')
          window.history.pushState({}, '', url)
        } else {
          const url = new URL(window.location)
          url.searchParams.delete('filter')
          window.history.pushState({}, '', url)
        }

        this.setFilterValue({ filter: 'pcon', value: this.clickedpc })
      },
      getProductStatusHandle(status) {
        switch (status) {
          case 'inshowroom':
            return 'inShowroom'
          case 'quickship':
            return 'quickShip'
          case 'ullisted':
            return 'ulListed'
          case 'new':
            return 'new'
          case 'buynow':
            return 'buyNow'
          case 'newyork':
            return 'newYork'
          default:
            return status
        }
      },
    },
  }
</script>

<template>
  <main id="main-content">
    <article class="plp">
      <ProductCategoryHeader
        v-if="title !== ''"
        :title="title"
        :first-select="firstSelected"
        :is-empty="emptyFilters"
        :brand-title="brandedTitle"
        :fil-count="filtersCount"
        :brand-slug="brandedSlug"
        :bread-crumb="breadCrumb"
        :parent-title="parentTitle"
        :summary="summary"
      />
      <div
        v-if="getPaginatedFeaturedProducts.length > 0"
        class="mx-auto lg:max-w-screen-2xl w-full px-4 md:px-6 pt-4"
      >
        <div class="transition--all pt-1 md:pt-0 w-full">
          <div class="w-full mb-0 md:mb-4">
            <h2 class="block w-full mb-0 font-cormorant font-bold">Featured Products</h2>
          </div>
          <FeaturedTileGrid
            class="md:pt-1"
            :hide-price="true"
            :products="getPaginatedFeaturedProducts"
            :destination="'ProductsListingPage'"
          />
        </div>
      </div>
      <div class="mx-auto lg:max-w-screen-2xl w-full px-4 md:px-6">
        <div v-if="getPaginatedFeaturedProducts.length > 0" class="w-full mb-0">
          <h2 class="block w-full mb-0 font-cormorant font-bold">Full Collection</h2>
        </div>
        <div class="hidden appendFilters w-full lg:flex-row items-center lg:flex mt-4 mb-2">
          <div
            class="filterHidden mr-12 flex flex-row flex-wrap items-center cursor-pointer"
            :class="`${moreWidth ? 'ml-0 lg:ml-1' : ''}`"
            @click="showSidebarFilters"
          >
            <span class="mr-2 font-regular leading-tight"
              >{{ `${moreWidth ? 'Show' : 'Hide'}` }} Filters</span
            >
            <FilterIcon />
          </div>
          <div class="quickShipFilter" :class="`${clicked ? 'active' : ''}`">
            Quick Ship
            <span @click="quickShipChecked"></span>
          </div>
          <div class="pconFilter" :class="`${clickedpc ? 'active' : ''}`">
            Build Your Own
            <span @click="pconChecked"></span>
          </div>
          <span
            v-if="!getIsLoading"
            class="block text-center mr-8 text-sm text-gray-600 || mobile"
            aria-current="true"
            aria-label="current products"
            v-html="shownQuantity + ' items selected'"
          ></span>
          <div class="filterButtons">
            <ul>
              <li v-for="(filter, idx) in getSelectedOption" :key="idx">
                <span @click="remoteSelectedFilter(filter.index)">
                  {{ filter.label }}
                  <IconClose width="12px" height="12px" />
                </span>
              </li>
            </ul>
          </div>
          <button
            v-if="getSelectedOption.length > 0"
            class="font-normal text-base text-black underline hover:no-underline"
            :class="getUsedFilters.length === 0 ? 'text-black' : ''"
            @click="clearAllFilters()"
          >
            Clear all
          </button>
          <div class="hidden lg:flex justify-end rightFilter">
            <!-- Pagination indicator medium screens -->
            <!--<nav ref="pagination" aria-label="Product pagination" class="hidden md:block mt-6 ml-6">
              <span
                :class="!getIsLoading ? 'visible' : 'invisible'"
                class="md:text-sm lg:text-base whitespace-no-wrap text-gray-500 || mobile"
                aria-current="true"
                aria-label="current products"
                v-html="'Showing ' + shownQuantity + ' of ' + totalQuantity + ' Products'"
              />
            </nav>-->
            <BaseFilterSelect
              v-if="isTradeAccount"
              label="Sort by"
              :options="sortOptions"
              :value="sort"
              class="lg:pr-6 mt-3"
              @change="setSort($event)"
            />
          </div>
        </div>
        <div v-if="!isTradeAccount" class="h-4 w-full"></div>
        <div
          class="static flex flex-col md:flex-row md:relative flex-wrap overflow-hidden mb-12"
          :class="filterBarOpen ? 'z-99' : ''"
        >
          <!-- <div
            v-if="moreWidth"
            @click="moreWidth = !moreWidth"
            class="filterActionBtn"
          >
            <span>+Filter</span>
          </div> -->
          <ProductFilter
            :has-header="title !== ''"
            :open="filterBarOpen"
            :getIsLoading="getIsLoading"
            :giveOpacity="giveOpacity"
            :moreWidth="moreWidth"
            :brandId="brandId ? brandId : null"
            @toggle="filterBarOpen = $event"
            :isTradeAccount="isTradeAccount"
          />
          <div
            v-if="filterBarOpen"
            class="z-20 absolute inset-0 lg:hidden bg-black bg-opacity-25"
            @click="filterBarOpen = false"
          ></div>
          <div
            class="transition--all"
            :class="`${moreWidth ? 'w-full' : 'w-full lg:w-9/12 xl:w-4/5'}`"
          >
            <ProductTileGrid
              class="md:pt-1"
              :moreWidth="moreWidth"
              :hide-price="hidePrice"
              :destination="'ProductsListingPage'"
            />
          </div>
        </div>
      </div>
    </article>
  </main>
</template>

<style scoped lang="scss">
  .quickShipFilter {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    height: 22px;
    margin-right: 50px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    cursor: pointer;

    span {
      position: relative;
      width: 40px;
      height: 100%;
      margin-left: 10px;
      background: #6f6f6f;
      border-radius: 50px;
      transition: all 0.25 ease-in-out;

      &::before {
        position: absolute;
        top: 2.11px;
        left: 2.11px;
        width: 17.78px;
        height: 17.78px;
        content: '';
        background: #fff;
        border-radius: 100%;
        transition: all 0.25 ease-in-out;
      }
    }

    &.active {
      span {
        background: #000;

        &::before {
          left: calc(100% - 19.89px);
        }
      }
    }
  }
  .pconFilter {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    height: 22px;
    margin-right: 50px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    cursor: pointer;

    span {
      position: relative;
      width: 40px;
      height: 100%;
      margin-left: 10px;
      background: #6f6f6f;
      border-radius: 50px;
      transition: all 0.25 ease-in-out;

      &::before {
        position: absolute;
        top: 2.11px;
        left: 2.11px;
        width: 17.78px;
        height: 17.78px;
        content: '';
        background: #fff;
        border-radius: 100%;
        transition: all 0.25 ease-in-out;
      }
    }

    &.active {
      span {
        background: #000;

        &::before {
          left: calc(100% - 19.89px);
        }
      }
    }
  }
  .filterHidden {
    span {
      font-size: 14px;
      position: relative;
      top: 1px;
    }
  }

  .rightFilter {
    width: 210px;
    margin-left: auto;
  }

  .appendFilters {
    position: relative;
    top: 0;
    z-index: 99;
    width: 100%;
  }

  .filterActionBtn {
    position: absolute;
    top: 75px;
    left: -14px;
    z-index: 999;
    cursor: pointer;
    transform: rotate(-90deg);

    span {
      font: 500 14px/14px 'Inter', sans-serif;
      color: #000;
    }
  }

  .transition--all {
    transition: all 0.25s ease-in-out;
  }

  .filterButtons {
    display: flex;
    width: auto;
    max-width: 600px;
    margin-right: 20px;

    ul {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: -10px;

      li {
        margin-right: 10px;
        margin-bottom: 10px;

        span {
          position: relative;
          display: inline-flex;
          align-items: center;
          height: 26px;
          padding: 0 37px 0 15px;
          font-family: 'Inter', sans-serif;
          font-size: 12px;
          font-weight: 500;
          line-height: 12px;
          color: #000;
          cursor: pointer;
          border: 1px solid #000;

          svg {
            position: absolute;
            top: 50%;
            right: 18px;
            transform: translateY(-50%);
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  @media (max-width: 1024px) {
    .stickySidebar {
      margin-left: 0 !important;
    }
  }
</style>
