<script>
  import { createNamespacedHelpers } from 'vuex'
  const { mapGetters: mapGlobalGetters } = createNamespacedHelpers('global')

  export default {
    name: 'ProductSpecs',

    props: {
      downloads: {
        type: Array,
        required: false,
        default: () => [],
      },
      loggedIn: {
        type: Boolean,
        default: false,
      },
      dimensions: {
        type: Array,
        default: () => [],
      },
      showNewLayout: {
        type:Boolean,
        default:false
      }
    },
    data() {
      return {
        isInches: true,
        score: 0.393701,
        specExtensions: [],
        otherExtensions: [],
      }
    },
    mounted() {
      this.downloads.forEach((item) => {
        if (item.extension !== 'pdf') {
          this.specExtensions.push(item)
        } else {
          this.otherExtensions.push(item)
        }
      })
    },
    methods: {
      switchUnit() {
        this.isInches = !this.isInches
      },
    },
  }
</script>

<template>
  <section
    v-if="
      $slots.details || $slots.dimensions || $slots.materials || $slots.finishes || downloads.length
    "
    :class="`${ showNewLayout ? 'xl:grid xl:grid-cols xl:gap-6 py-6' : 'pb-6 border-b pt-6 xl:grid xl:grid-cols-8 xl:gap-6' }`"
  >
    <div v-if="showNewLayout">
      <h2 class="mb-6 xl:col-span-2 hidden">Specs</h2>

      <div class="xl:col-span-12">
        <dl class="wysiwyg">
          <dt v-if="$slots.details" class="mb-2 font-semibold uppercase">Details</dt>
          <dd v-if="$slots.details" class="mb-6 text-gray-600 leading-snug">
            <slot name="details"></slot>
          </dd>

          <div v-if="dimensions.length > 0">
            <div class="w-full flex flex-row justify-between items-center pt-0 mb-8">
              <h3 class="mb-0 font-semibold uppercase">Dimensions</h3>
              <div class="unit--switcher">
                <i>Cm</i>
                <div class="switch--button">
                  <input type="checkbox" name="unitswitch" @change="switchUnit" checked />
                  <span></span>
                </div>
                <i>In</i>
              </div>
            </div>
            <div class="specs--content flex flex-row justify-between flex-wrap">
              <div v-for="spec in dimensions" :key="spec.title" class="mb-3 w-full sm:w-3/6">
                <h4 class="block w-full mb-2 text-sm font-medium">{{
                  spec.customName ? spec.customName : spec.title
                }}</h4>
                <ul>
                  <li class="mb-2 text-sm" v-for="item in spec.items" :key="item.name">
                    <span class="mr-1 text-gray-600"
                      >{{ item.customName ? item.customName : item.name }}:
                    </span>
                    <em class="not-italic" v-if="item.min && item.max">
                      {{ isInches ? (parseInt(item.min) * score).toFixed(1) : parseInt(item.min) }} -
                      {{ isInches ? (parseInt(item.max) * score).toFixed(1) : parseInt(item.max) }}
                      {{ isInches ? 'in' : 'cm' }}
                    </em>
                    <em class="not-italic" v-else-if="item.min && !item.max">
                      {{ isInches ? (parseInt(item.min) * score).toFixed(1) : parseInt(item.min) }}
                      {{ isInches ? 'in' : 'cm' }}
                    </em>
                    <em class="not-italic" v-else-if="!item.min && item.max">
                      {{ isInches ? (parseInt(item.max) * score).toFixed(1) : parseInt(item.max) }}
                      {{ isInches ? 'in' : 'cm' }}
                    </em>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <dt v-if="$slots.materials" class="mb-2 font-semibold uppercase">Materials</dt>
          <dd v-if="$slots.materials" class="mb-6 text-gray-600 leading-snug">
            <slot name="materials"></slot>
          </dd>

          <dt v-if="$slots.finishes" class="mb-2 font-semibold uppercase">Finishes</dt>
          <dd v-if="$slots.finishes" class="mb-6 text-gray-600 leading-snug">
            <slot name="finishes"></slot>
          </dd>

          <!-- <dt
            v-if="otherExtensions.length || specExtensions.length"
            class="mb-2 font-semibold uppercase"
            >Downloads</dt
          >
          <dd
            v-if="otherExtensions.length || specExtensions.length"
            class="text-gray-600 leading-snug"
          >
            <ul>
              <li v-for="(file, index) in otherExtensions" :key="index">
                <a :href="file.url" target="_blank" class="download--btn">
                  {{ file.label }} ({{ file.extension.toUpperCase() }})
                </a>
              </li>
            </ul>
            <ul v-if="loggedIn">
              <li v-for="(file, index) in specExtensions" :key="index">
                <a :href="file.url" target="_blank" class="download--btn">
                  {{ file.label }} ({{ file.extension.toUpperCase() }})
                </a>
              </li>
            </ul>
          </dd> -->
        </dl>
      </div>
    </div>
    <div class="xl:col-span-12" v-else>
      <dl class="wysiwyg">
        <dt v-if="$slots.details" class="mb-2 font-bold">Details</dt>
        <dd v-if="$slots.details" class="mb-6 text-black leading-snug">
          <slot name="details"></slot>
        </dd>

        <dt v-if="$slots.dimensions" class="mb-2 font-bold">Dimensions</dt>
        <dd v-if="$slots.dimensions" class="mb-6 text-black leading-snug">
          <slot name="dimensions"></slot>
        </dd>

        <dt v-if="$slots.materials" class="mb-2 font-bold">Materials</dt>
        <dd v-if="$slots.materials" class="mb-6 text-black leading-snug">
          <slot name="materials"></slot>
        </dd>

        <dt v-if="$slots.finishes" class="mb-2 font-bold">Finishes</dt>
        <dd v-if="$slots.finishes" class="mb-6 text-black leading-snug">
          <slot name="finishes"></slot>
        </dd>

        <!-- <dt
          v-if="otherExtensions.length || specExtensions.length"
          class="mb-2 font-semibold uppercase"
          >Downloads</dt
        >
        <dd
          v-if="otherExtensions.length || specExtensions.length"
          class="text-gray-600 leading-snug"
        >
          <ul>
            <li v-for="(file, index) in otherExtensions" :key="index">
              <a :href="file.url" target="_blank" class="download--btn">
                {{ file.label }} ({{ file.extension.toUpperCase() }})
              </a>
            </li>
          </ul>
          <ul v-if="loggedIn">
            <li v-for="(file, index) in specExtensions" :key="index">
              <a :href="file.url" target="_blank" class="download--btn">
                {{ file.label }} ({{ file.extension.toUpperCase() }})
              </a>
            </li>
          </ul>
        </dd> -->
      </dl>
    </div>
  </section>
</template>

<style scoped lang="postcss">
  .specs--content {
    width: 100%;
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .unit--switcher {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100px;

    i {
      font: 500 12px/12px 'Inter', sans-serif;
      font-style: normal;
      text-transform: uppercase;
    }

    .switch--button {
      position: relative;
      width: 40px;
      height: 22px;
      overflow: hidden;
      background: #000;
      border-radius: 50px;

      input {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;
        display: block;
        width: 100%;
        height: 100%;
        cursor: pointer;
        background: transparent;
        opacity: 0;
      }

      span {
        position: absolute;
        top: 2px;
        left: 2.3px;
        display: block;
        width: 18px;
        height: 18px;
        background: #fff;
        border-radius: 100%;
        transition: all 0.25s ease-in-out;
      }
    }

    .switch--button input:checked + span {
      left: 20px;
    }
  }

  .wysiwyg a {
    border-color: #6f6f6f;
    border-style: solid;
  }

  .wysiwyg a.download--btn {
    font-size: 14px;
    font-weight: 400;
    line-height: 130%;
    color: rgb(202, 24, 24);
    border-color: rgb(202, 24, 24);

    &:hover {
      border-color: transparent;
    }
  }
</style>
